const Explore = {
  async render() {
    return `
      <h2>Explore Restaurants</h2>
      <restaurants-container></restaurants-container>
    `;
  },

  async afterRender() {},
};

export default Explore;
