import FavoriteRestaurantIdb from '../../data/favorite-restaurant-idb';

const Favorite = {
  async render() {
    return `
      <h1>Favorite</h1>
    `;
  },

  async afterRender() {
    const restaurants = await FavoriteRestaurantIdb.getAllRestaurants();
    const mainElem = document.querySelector('main');
    if (restaurants.length > 0) {
      const restaurantsContainer = document.createElement(
        'restaurants-container'
      );
      mainElem.append(restaurantsContainer);
      restaurantsContainer.restaurants = restaurants;
    } else {
      const msg = document.createElement('p');
      msg.innerHTML = 'No favorite restaurant yet';
      mainElem.append(msg);
    }
  },
};

export default Favorite;
