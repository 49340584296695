import Swal from 'sweetalert2';
import restaurantApi from '../../data/restaurant-api';

const Detail = {
  async render() {
    return `
        <restaurant-detail id="restaurant"></restaurant-detail>
    `;
  },

  async afterRender({ params }) {
    try {
      const restaurantId = params.id;
      const restaurant = await restaurantApi.detail(restaurantId);
      const detailElement = document.querySelector('#restaurant');
      detailElement.restaurant = restaurant;
      document.title = `FeastFinder - ${restaurant.name}`;
    } catch (error) {
      console.log(error);
      await Swal.fire('Error!', 'Failed to load restaurant data', 'error');
    }
  },
};

export default Detail;
