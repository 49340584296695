const NotFound = {
  async render() {
    return `
        <h1>404 Not Found</h1>
    `;
  },

  async afterRender() {},
};

export default NotFound;
