import Swal from 'sweetalert2';
import RestaurantApi from '../../data/restaurant-api';

const Home = {
  async render() {
    return `
      <app-hero></app-hero>
      <section class="explore">
        <h2>Explore Restaurants</h2>
        <restaurants-container></restaurants-container>
      </section>
    `;
  },

  async afterRender() {
    try {
      const restaurants = await RestaurantApi.list();
      document.querySelector('restaurants-container').restaurants = restaurants;
    } catch (error) {
      console.log(error);
      await Swal.fire('Error!', 'Failed to load restaurants data', 'error');
    }
  },
};

export default Home;
